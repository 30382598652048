import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'
import Layout from "../layouts"

import GetImgUrl from "../utils/customFunctions"

import ExitIntent from "../utils/ExitIntent"

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"
import LatestNews from "../components/latest-news/latest-news";
import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"
import HomeCards from "../components/home-cards/home-cards";
import Comparison from "../components/comparison/comparison";
import ImageCarousel from "../components/image-carousel/image-carousel";
import ExitIntentModal from "../components/exit-intent-modal/exit-intent-modal"
import LogoTransition from "../components/logo-transition/logo-transition"



// markup
const IndexPage = () => {

  const data = useStaticQuery(graphql`
        {
          strapiHomepage {
            PageComponents
            SEOTitle
            SEODescription
            FooterDisclaimer
            MetaTags{
              MetaName
              MetaContent
            }
          }

          allFile {
            edges {
              node {
                publicURL
                id
                name
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          

          strapiSiteSettings {
              LogoDark {
                url
                localFile {
                  publicURL
                }
              }
              SiteTitle
              SocialLinks {
                FacebookURL
                LinkedinURL
                TwitterURL
                YoutubeURL
              }
              IntentCTALabel
              IntentDescription
              IntentTitle
              EnableIntent
              IntentBgImage {
                localFile{
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        gatsbyImageData(
                          width: 1900
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                    }
                }
              }
              IntentIcon {
                localFile{
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                        gatsbyImageData(
                          width: 1900
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                    }
                }
              }

          }
        }
    `)





  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {

        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null


      }
      return ("")

    })

    return (imgUrl)
  }




  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 30,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true)
      },
    })
    return () => {
      removeExitIntent()
    }

  })






  return (
    <Layout SEOTitle={data.strapiHomepage.SEOTitle} SEODescription={data.strapiHomepage.SEODescription} footerDisclaimer={data.strapiHomepage.FooterDisclaimer} meta={data.strapiHomepage.MetaTags}>


      {data.strapiHomepage.PageComponents.map((item, index) => {


        switch (item.strapi_component) {

          case "page-components.header":

            return (
              <>

                <Header key={index}
                  title={item.Title}
                  markdownTitle={item.MarkdownTitle}
                  subtitle={item.Subtitle}
                  secondarySubtitle={item.SecondSubtitle}
                  CTAText={item.CTALabel}
                  CTAUrl={item.CTAUrl}
                  CTA_nofollow={item.CTA_nofollow}
                  Disclaimer={item.Disclaimer}
                  Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                  image={item.image !== null ? getImgUrl(item.image?.localFile___NODE) : ""}
                  FirstLogo={item.FirstLogo !== null ? getImgUrl(item.FirstLogo?.localFile___NODE) : ""}
                  SecondLogo={item.SecondLogo !== null ? getImgUrl(item.SecondLogo?.localFile___NODE) : ""}
                  HeaderHeight={item.HeaderHeight}
                ></Header>
              </>

            )



          case "page-components.comparison":
            return (

              <Comparison key={index}
                title={item.Title}
                step1={item.Step1}
                step2={item.Step2}
                step3={item.Step3}
                image1={getImgUrl(item.Image1.localFile___NODE)}
                image2={getImgUrl(item.Image2.localFile___NODE)}
                image3={getImgUrl(item.Image3.localFile___NODE)}
              ></Comparison>


            )


          case "page-components.cta-banner":
            return (

              <CTABanner
                key={index}
                Title={item.Title}
                Subtitle={item.Subtitle}
                ctaUrl={item.CTAUrl}
                ctaLabel={item.CTALabel}
              ></CTABanner>

            )
          case "page-components.image-slider":
            var contentsList = []

            item.Picture.map((image, index) => {

              var imageUrl = GetImgUrl(image.Image.localFile___NODE).gatsbyImageData
              contentsList.push({
                ALT: image.ALT,
                Image: imageUrl,
              })
              return ("")
            })
            return (

              <ImageCarousel
                key={index}
                contents={contentsList}
              ></ImageCarousel>
            )
          case "page-components.banner":
            return (

              <Banner
                key={index}
                text={item.Text}
                image={getImgUrl(item.Image.localFile___NODE)}
                imageAlt={item.Image.alternativeText}
                dark={item.DarkBackground}
                title={item.Title}
                subtitle={item.Subtitle}
              ></Banner>

            )

          case "page-components.home-cards":
            var contentsList = []
            item.CTACard.map((CTAitem, index) => {
              var imageUrl = CTAitem.Image !== null ? getImgUrl(CTAitem.Image.localFile___NODE) : ""
              contentsList.push({
                Title: CTAitem.Title,
                URL: CTAitem.URL,
                Image: imageUrl,
                Description: CTAitem.Description,
              })
              return ("")
            })
            return (

              <HomeCards
                key={index}
                contents={contentsList}
              ></HomeCards>


            )

          case "page-components.paragraph":
            return (

              <Paragraph
                key={index}
                title={item.Title ? item.Title : ""}
                subtitle={item.SubTitle ? item.SubTitle : ""}
                text={item.Text}
                bgColor={item.BackgroundColor || ""}

              ></Paragraph>
            )


          case "page-components.cta-list":
            var contentsList = []
            item.CTAList.map((CTAitem, index) => {
              var imageUrl = CTAitem.Image !== null ? getImgUrl(CTAitem.Image.localFile___NODE) : ""
              contentsList.push({
                Title: CTAitem.Title,
                URL: CTAitem.URL,
                URLLabel: CTAitem.URLLabel,
                Image: imageUrl,
                Alt: CTAitem.Image.alternativeText,
                Description: CTAitem.Description,
                DescriptionCharLimit: CTAitem.DescriptionCharLimit
              })
              return ("")
            })
            return (

              <CTAList
                key={index}
                title={item.Title ? item.Title : ""}
                subtitle={item.Subtitle}
                contents={contentsList}
                cardStyle={item.CardStyle}
              ></CTAList>


            )

          case "page-components.latest-news":
            return (
              <LatestNews
                key={index}
                title={item.Title}
                subtitle={item.Subtitle}
                bgColor={item.BackgroundColor}
              />
            )

          default:
            return ("")
        }



      })}

      {/* <FormCompetitions /> */}

      <ExitIntentModal
        show={showPopup}
        intentTitle={data.strapiSiteSettings.IntentTitle}
        intentDescription={data.strapiSiteSettings.IntentDescription}
        ctaLabel={data.strapiSiteSettings.IntentCTALabel}
        bgImage={data.strapiSiteSettings.IntentBgImage}
        enableIntent={data.strapiSiteSettings.EnableIntent}
        icon={data.strapiSiteSettings.IntentIcon}
      />


    </Layout>
  )

}

export default IndexPage