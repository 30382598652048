import BackgroundImage from "gatsby-background-image";
import React, { useState, useEffect } from "react"
import icon from "../../assets/newsletter.png"
import { AiOutlineCloseSquare } from "react-icons/ai"
import "./exit-intent-modal.scss";
import { GatsbyImage } from "gatsby-plugin-image";


const ExitIntentModal = (props) => {
    const [show, setShow] = useState(props.show)
    useEffect(() => {
        if (props.show) {
            let expiryDate = new Date(
                Date.now() + 14 * (1000 * 60 * 60 * 24)
            )
            expiryDate.setFullYear(expiryDate.getFullYear() + 1)
            document.cookie =
                "modal_seen" + "=true; expires=" + expiryDate.toUTCString()
        }
        setShow(props.show)
    }, [props.show])


    const close = e => {
        if (e.target.className === 'exit-intent-popup' || // user clicks on mask
            e.target.className === 'close') {             // user clicks close btn
            //console.log(e.target.className, "mouse")
            setShow(false)
        }
    }





    const [email, setEmail] = useState("");
    const [form, setForm] = useState();

    const [error, setError] = useState(false);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const validate = (e) => {

        let emailError = "";
        let isNotValid = false;

        if (form.email === "" || isValidEmail(form.email) === false) {
            emailError = "This field is required.";
            isNotValid = true;
        }

        return isNotValid;
    }

    const result = e => {
        let value = validate();
        setError(value);
    }


    (function () {
        let fnames = new Array();
        let ftypes = new Array();
        fnames[0] = 'EMAIL';
        ftypes[0] = 'email';
        fnames[1] = 'FNAME';
        ftypes[1] = 'text';
        fnames[2] = 'LNAME';
        ftypes[2] = 'text';
        fnames[3] = 'ADDRESS';
        ftypes[3] = 'address';
        fnames[4] = 'PHONE';
        ftypes[4] = 'phone';
        fnames[5] = 'BIRTHDAY';
        ftypes[5] = 'birthday';
    }
        ());


    return (
        <>
            {
                (show && props.enableIntent) ?
                    <div className="exit-intent-popup" onClick={(e) => close(e)}>
                        <div className="newsletter">
                            <BackgroundImage Tag="section" role="img" fluid={props.bgImage.localFile.childImageSharp.fluid} className="popup-bg">
                                <div className="popup-content">
                                    <div className="modal-subscribe-icon">
                                        <GatsbyImage className="modal-img" image={props.icon.localFile.childImageSharp.gatsbyImageData} alt={""}></GatsbyImage>
                                    </div>
                                    <div className="modal-subscribe-content">
                                        <b>{props.intentTitle}</b>
                                        <p>{props.intentDescription}</p>
                                        <div className="centered-modal-fields">
                                            
                                            <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
                                            <div id="mc_embed_signup">
                                                <form action="https://wayfarersarcade.us18.list-manage.com/subscribe/post?u=5fd00cee0a74e2316d00a39ca&amp;id=002ab63c42&amp;f_id=002d27e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                                    <div id="mc_embed_signup_scroll">
                                                        {/* <h2>Subscribe</h2> */}
                                                        {/* <div className="indicates-required"><span className="asterisk">*</span> indicates required</div> */}
                                                        <div className="mc-field-group">
                                                            {/* <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                                                            </label> */}
                                                            <input type="email" value={email} name="EMAIL" className="required_email" id="mce-EMAIL" onChange={(e) => setEmail(e.target.value)} required placeholder="Your email address" />
                                                            {/* <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span> */}
                                                        </div>
                                                        <div hidden="true"><input type="hidden" name="tags" value="2525070"/></div>
                                                        <div id="mce-responses" className="clear foot">
                                                            <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                                                            <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                                                        </div>  
                                                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_5fd00cee0a74e2316d00a39ca_002ab63c42" tabindex="-1" value=""/></div>
                                                        <div className="optionalParent">
                                                            <div className="clear foot">
                                                                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
                                                                <p className="brandingLogo"><a href="http://eepurl.com/ikEp0z" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"/></a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                                            {/* <input type="email" placeholder="Your email address" className={`email ${error ? 'red-border' : ''}`} name="email" value={form.email} pattern=".+@globex\.com" required={true} onChange={onUpdateField} />
                                            <button type="submit" className="button button-primary" onClick={() => result()} id="submit-btn-intent">{props.ctaLabel}</button> */}
                                        </div>
                                    </div>
                                    <span className="close" onClick={(e) => close(e)}>&times;</span>
                                    

                                </div>
                            </BackgroundImage>
                        </div>

                    </div>
                    : ""
            }
        </>
    )

}

export default ExitIntentModal
